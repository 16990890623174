@import "https://fonts.googleapis.com/css?family=Quicksand";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "lib/assets/scss/colors.scss";
@import "lib/assets/scss/sizes.scss";


html,
body {
    margin: 0;
    font-family: 'Quicksand';
    font-size: $default-font;
    line-height: $large-line-height;

    p {
        line-height: $large-line-height;
    }

    button,
    a {
        font-family: 'Quicksand';
        //background: $button-color;
    }

    .mdc-form-field {
        font-family: 'QuickSand';
    }

    pw-typeofdelivery,
    pw-contact,
    pw-typeofpayment,
    pw-cartsum,
    pw-cartsummary,
    pw-loggedinuserpersonaldata,
    pw-myorders,
    pw-verification,
    pw-verification-forgot-password {
        width: 90%;
    }

    pw-discountedproduct, pw-specialoffer {
        width:$content-max-size;
    }
    pw-publication {
        padding-bottom:$large-padding-bottom;
    }
    lib-toastmessage, .toast-class,.success-toast,.warning-toast,.danger-toast {
        z-index:1000000000;
    }

    h5 {
        font-size: $default-font;
    }

}

.back-drop-background {
    background-color: rgba(0, 0, 0, 0.5);
}

.mat-mdc-option.mdc-list-item-disabled {
    opacity: 0;

}

.mat-mdc-option.mdc-list-item {
    min-height: 32px;

    &:hover {
        background: #eee;
    }

}

.cdk-overlay-pane {
       background: white;
       font-size: $small-font;

}

.mat-mdc-select-panel-above .mdc-menu-surface.mat-mdc-select-panel {
    position: absolute;
    background: white;
}

.mat-mdc-select-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    font-size: $small-font;


}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $materials-background;
    border-radius: $mini-border-radius;
}

.cdk-global-overlay-wrapper {
    overflow: auto;
    pointer-events: auto;
}

.mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
    color: $text-light;
    text-transform: uppercase;
    font-size: 18px;
    padding: 8px;
    font-family: 'QuickSand';
}

@media(max-width:$tablet-view) {
    .cdk-overlay-pane {
        background: white;
        font-size: $small-font;
        opacity: 0.9;



    }

    .mat-mdc-menu-panel.mat-mdc-menu-panel {
        width: 100vw;
        height: 60vh;
        background: $primary-light;
        opacity: 0.9;
        box-shadow: none;

    }

    .mat-mdc-select-trigger {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        font-size: $mini-font;


    }

}
